//
// custom-forms.scss
//

@each $color, $value in $theme-colors {
    .form-check-#{$color},
    .form-radio-#{$color} {
        .form-check-input {
            &:checked {
                background-color: $value;
                border-color: $value;
            }
        }
    }
}

// card radio
.card-radio {
    padding: 0;

    .form-check-label {
        background-color: $card-bg;
        border: 1px solid var(--#{$prefix}border-color);
        border-radius: var(--#{$prefix}border-radius);
        padding: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        position: relative;
        padding-right: 32px;

        &:hover {
            cursor: pointer;
        }
    }

    .form-check-input {
        display: none;

        &:checked + .form-check-label {
            &:before {
                content: "\f05e0";
                font-family: "Material Design Icons";
                position: absolute;
                bottom: 2px;
                right: 6px;
                font-size: 16px;
                color: $primary;
            }
        }
    }

    &.dark {
        .form-check-input {
            &:checked + .form-check-label {
                &:before {
                    color: $white;
                }
            }
        }
    }
}
