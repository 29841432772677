//
// Daterange
//

.daterangepicker {
    font-family: $font-family-base;
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: var(--#{$prefix}box-shadow);
    background-color: $dropdown-bg;

    .calendar-table {
        border: 1px solid $dropdown-bg;
        background-color: $dropdown-bg;

        td,
        th {
            color: var(--#{$prefix}gray-600);
        }

        .next,
        .prev {
            span {
                border-color: var(--#{$prefix}body-color);
            }
        }
    }

    .ranges {
        li {
            &:hover {
                background-color: $dropdown-link-hover-bg;
            }
            &.active {
                background-color: $primary;
            }
        }
    }

    td {
        &.in-range {
            background: var(--#{$prefix}gray-100);
            color: var(--#{$prefix}secondary-text);
        }
        &.off,
        &.off.end-date,
        &.off.in-range,
        &.off.start-date {
            background-color: $dropdown-bg;
            color: $dropdown-link-color;
            opacity: 0.5;
        }

        &.active,
        &.active:hover {
            background-color: $primary;
            color: $white;
        }
    }

    td.available:hover,
    th.available:hover,
    td.active,
    td.active:hover {
        background-color: $primary;
        color: $white;

        span {
            border-color: $white;
        }
    }

    &:after {
        border-bottom: 6px solid $dropdown-bg;
    }
    &:before {
        border-bottom: 7px solid $dropdown-border-color;
    }

    .drp-buttons {
        border-top: 1px solid $dropdown-border-color;
    }

    select {
        &.ampmselect,
        &.hourselect,
        &.minuteselect,
        &.secondselect {
            background: var(--#{$prefix}gray-100);
            border: 1px solid var(--#{$prefix}gray-100);
            color: $dropdown-link-color;
        }
    }

    &.show-ranges {
        .drp-calendar.left {
            border-left: 1px solid $dropdown-border-color !important;
        }
    }
}
